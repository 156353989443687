import { orgQueries } from '@repo/db/queries/organization'
import { userQueries } from '@repo/db/queries/user'
import { UIProvider } from '@repo/ui'
import { useToaster } from '@repo/ui/hooks/useToaster'
import type { ReactNode } from 'react'
import {
  Links,
  type LoaderFunctionArgs,
  Meta,
  type MetaArgs,
  Outlet,
  Scripts,
  ScrollRestoration,
  data,
  useLoaderData,
  useRouteError,
} from 'react-router'
import { ErrorCard } from './components/ErrorCard'
import { authenticator } from './services/auth/auth.server'
import { getIntercomHash } from './services/intercom.server'
import { getCookie } from './utils/cookie.server'
import { env } from './utils/env.server'
import { getToast } from './utils/toast.server'

import '@repo/tailwind/tailwind.css'

declare global {
  interface Window {
    ENV: Awaited<ReturnType<typeof loader>>['data']['ENV']
  }
}

export const meta = ({ data }: MetaArgs<typeof loader>) => {
  return [{ title: `${data?.ENV.PUBLIC.APP_NAME} Pulse` }]
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const [toast, activeOrg, preferences] = await Promise.all([
    getToast(request),
    getCookie(request, 'activeOrg'),
    getCookie(request, 'preferences'),
  ])

  const auth = await authenticator.getAuthSession(request)

  const [user, org] = await Promise.all([
    auth
      ? userQueries.getAuthenticatedUser(auth.userId, activeOrg?.orgId)
      : null,
    activeOrg ? orgQueries.getById(activeOrg.orgId) : null,
  ])

  return data(
    {
      ENV: {
        APP_ENV: env.APP_ENV,
        PUBLIC: env.PUBLIC,
        CHROME_EXTENSION_ID: env.CHROME_EXTENSION_ID,
        EDGE_EXTENSION_ID: env.EDGE_EXTENSION_ID,
        INTERCOM_APP_ID: env.INTERCOM_APP_ID,
        INTERCOM_HASH: getIntercomHash(auth?.userId),
        SENTRY_DSN: env.SENTRY_DSN,
      },
      toast: toast.toast,
      authenticatedUser: user,
      authenticatedOrg: org,
      activeOrg,
      preferences,
    },
    { headers: { 'set-cookie': toast.cookie } }
  )
}

export function Layout({ children }: { children: ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="scheme-light dark:scheme-dark font-sans text-primary text-sm antialiased">
        <UIProvider>{children}</UIProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()

  return (
    <div className="flex h-dvh flex-col items-center justify-center bg-secondary">
      <ErrorCard error={error} />
    </div>
  )
}

export default function App() {
  const { toast } = useLoaderData<typeof loader>()
  useToaster(toast)
  return <Outlet />
}
