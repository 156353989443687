import type { PropsWithChildren } from 'react'
import { Toaster } from '#components/ui/Toaster.js'
import { TooltipProvider } from '#components/ui/Tooltip.js'

export const UIProvider = ({ children }: PropsWithChildren) => {
  return (
    <>
      <TooltipProvider delayDuration={400}>{children}</TooltipProvider>
      <Toaster />
    </>
  )
}
